import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Tab } from '@sellpy/design-system-react-web'
import { isFeatureEnabled, FEATURE_SELL } from '../../featureToggle'
import { analyticsMenuNavigation } from '../../../common/analytics/actions'
import { useHeaderHeight } from '../../hooks'
import { mediaQueries } from '../../uiComponents/mediaQueries.js'
import BottomContent from './BottomContent.jsx'
import BuyOrSellList from './buyOrSellList/BuyOrSellList.jsx'

const StyledContent = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.color.grey.shade8}`};
  position: fixed;
  background-color: white;
  left: 0;
  top: ${({ $height }) => `${$height}px`};
  z-index: 5000;
  height: ${({ $height }) => `calc(100% - ${$height}px)`};
  overflow-y: auto;
  /* This number will align with the headerlogo */
  width: 406px;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: transform 0.3s ease-in-out, visibility 0.3s;
  ${mediaQueries.phone} {
    width: 90%;
  }
`

const StyledTab = styled(Tab)`
  background-color: ${({ active, theme }) => (active ? theme.color.grey.shade10 : 'white')};
  border-right: 1px solid ${({ theme }) => theme.color.grey.shade8};
`

const Content = ({ isOpen, closeMenu, id }) => {
  const currentHeight = useHeaderHeight(true)
  const [section, setSection] = useState('buy')
  const ref = useRef()

  useEffect(() => {
    if (!isOpen && ref.current) {
      ref.current.scrollTo(0, 0)
    }
  }, [isOpen])

  const { t } = useTranslation('menu')

  return (
    <StyledContent
      $height={currentHeight}
      isOpen={isOpen}
      aria-hidden={!isOpen}
      role='menu'
      id={id}
    >
      {isFeatureEnabled(FEATURE_SELL) ? (
        <div style={{ display: 'flex' }} role='tablist'>
          <StyledTab
            label={t('buy')}
            active={section === 'buy'}
            onClick={() => {
              ref.current.scrollTo(0, 0)
              setSection('buy')
              analyticsMenuNavigation({ label: 'openBuy', action: 'clickTab' })
            }}
          />
          <StyledTab
            label={t('sell')}
            active={section === 'sell'}
            onClick={() => {
              ref.current.scrollTo(0, 0)
              setSection('sell')
              analyticsMenuNavigation({ label: 'openSell', action: 'clickTab' })
            }}
          />
        </div>
      ) : (
        <div style={{ display: 'flex' }} />
      )}
      <div ref={ref}>
        <BuyOrSellList
          section={section}
          closeMenu={closeMenu}
          scrollToTop={() => ref.current.scrollTo(0, 0)}
          isOpen={isOpen}
        />
        <BottomContent section={section} closeMenu={closeMenu} />
      </div>
    </StyledContent>
  )
}

export default Content
