import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { P } from '@sellpy/design-system-react-web'
import { RADIO_BUTTON_DIRECTIONS } from './RadioGroup.jsx'

// Determines colour of the radio button label.
const _defaultLabelColor = ({ disabled, theme }) =>
  disabled ? theme.color.grey.shade6 : theme.color.grey.shade2

// Determines colour of the radio button icon when filled, i.e. input is checked.
const _defaultFilledColor = ({ disabled, erroneous, theme }) =>
  disabled
    ? theme.color.grey.shade4
    : erroneous
    ? theme.color.red.default
    : theme.color.blue.default

// Shows the hover/focus circle element
const hoverCircleEffect = ({ disabled, erroneous, theme }) => {
  return (
    !disabled &&
    `
    border-radius: 20px;
    background-color: ${theme.color.grey.shade8};

    &:not(:checked) {
      color: ${erroneous ? theme.color.red.default : theme.color.grey.shade3};
    }`
  )
}

const StyledLabel = styled(P)`
  color: ${_defaultLabelColor};
  width: ${({ direction }) => (direction === RADIO_BUTTON_DIRECTIONS.RIGHT ? '100%' : 'auto')};
  padding: 8px 0px 8px 0px;
  a[href] {
    color: ${({ theme }) => theme.color.blue.default};
    margin: -8px 0px -8px 0px;
    padding: 8px 0px 8px 0px;
    display: inline-block;
  }
`

const StyledInput = styled.input.attrs({
  type: 'radio'
})`
  --icon-radio-button: '\\E8FE';
  --icon-radio-button-filled: '\\E8FD';
  appearance: none;
  border: none;
  font-size: 24px;
  line-height: 24px;
  width: 40px;
  height: 40px;
  align-self: center;
  background-color: transparent;
  font-family: 'fontello';  
  margin: 0;
  ${({ direction }) =>
    direction === RADIO_BUTTON_DIRECTIONS.RIGHT ? 'margin-right: -8px;' : 'margin-left: -8px;'}
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  color: ${({ erroneous, theme }) =>
    erroneous ? theme.color.red.default : theme.color.grey.shade6};

  ::before {
    /* Radio button icon */
    content: var(--icon-radio-button);
    position: relative;
    visibility: visible;
    top: 8px;
    padding: 8px;
  }

  &:checked {
    color: ${_defaultFilledColor};
    ::before {
      /* Radio button icon */
      content: var(--icon-radio-button-filled);
    }
  }

  &:focus-visible,
  &:active
  {
    outline: none;
    ${hoverCircleEffect}
  }

`

const RadioButtonWrapper = styled.label`
  position: relative;
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  align-items: stretch;
  min-height: 40px;

  ${({ direction }) =>
    direction === RADIO_BUTTON_DIRECTIONS.RIGHT
      ? 'flex-direction: row-reverse;'
      : 'flex-direction: row;'}

  -webkit-tap-highlight-color: transparent;
  user-select: none;

  @media (hover: hover) {
    :hover {
      :not(:has(a:hover)) {
        input {
          ${hoverCircleEffect}
        }
      }
      @supports not selector(: has(a: hover)) {
        /* if :has isn't officially supported (https://caniuse.com/css-has) 
           we show the hover effect even when hovering over links 
        */
        input {
          ${hoverCircleEffect}
        }
      }
    }
  }
`

const RadioButtonComponent = (
  { value, label, disabled, direction, name, onChange, onBlur, erroneous },
  ref
) => {
  return (
    <RadioButtonWrapper disabled={disabled} direction={direction} erroneous={erroneous}>
      <StyledInput
        ref={ref}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        erroneous={erroneous}
      />
      <StyledLabel design='body1' noMargin disabled={disabled} direction={direction}>
        {label['label'] || label}
      </StyledLabel>
    </RadioButtonWrapper>
  )
}

export const transStringElementLabelValidation = (props, propName, componentName) => {
  const propValue = props[propName]
  const isReactElement = React.isValidElement(propValue)

  return typeof propValue === 'string' || propValue?.type === Trans || isReactElement
    ? undefined
    : new Error(
        `
      Invalid label type supplied to ${componentName} 
      { name: ${props['name']}, value: ${props['value']} }.
      A label prop can only be a string, a Trans component, or a React element.
      `
      )
}

const RadioButton = React.forwardRef(RadioButtonComponent)
RadioButton.displayName = RadioButtonComponent.name
RadioButton.propTypes = {
  label: transStringElementLabelValidation,
  erroneous: PropTypes.bool.isRequired
}

export default RadioButton
