import Parse from 'parse'
import { user as userTools } from '@sellpy/commons'
import { defer } from 'react-router-dom'
import { getABTestFraction } from '../../common/analytics/abTest.js'
import { getCreateQueryPreloader } from '../apollo/apolloClientSingleton.js'
import { region } from '../../common/region/region.js'
import getLandingPage from './queries/landingPage.gql'
import getPreviewLandingPage from './queries/previewLanding.gql'
import GET_USER_DEMOGRAPHY from './queries/userDemography.gql'

const roundedClientAbTestGroupValue = (Math.round(getABTestFraction() / 0.05) * 0.05).toFixed(2)

export const landingPageLoader = async ({ request }) => {
  const url = new URL(request.url)
  const sanityDraftId = url.searchParams.get('sanityDraftId')
  const segmentValue = url.pathname.split('/')[1] || 'root'
  const clientAbTestGroup = roundedClientAbTestGroupValue

  const abTestMinMax =
    clientAbTestGroup > 1 || clientAbTestGroup < 0 || !Number(clientAbTestGroup)
      ? 0
      : Number(clientAbTestGroup)

  return defer({
    demography: getCreateQueryPreloader()(GET_USER_DEMOGRAPHY),
    sanityData: getCreateQueryPreloader()(sanityDraftId ? getPreviewLandingPage : getLandingPage, {
      variables: {
        ...(sanityDraftId
          ? { id: sanityDraftId, isFullUser: userTools.userIsFull(Parse.User.current()) }
          : {
              segment: segmentValue,
              region: region(),
              isFullUser: userTools.userIsFull(Parse.User.current()),
              clientAbTestGroup: abTestMinMax
            })
      },
      context: { api: 'sanity', preview: Boolean(sanityDraftId) }
    })
  })
}
