import React from 'react'
import config from 'config'
import styled from 'styled-components'
import { Grid, H7, P } from '@sellpy/design-system-react-web'
import { Link } from 'react-router-dom'
import { NotificationBadge } from '../../uiComponents'
import { mediaQueries } from '../../uiComponents/mediaQueries'

const region = process.env.REGION

const HEADER_HEIGHT = 57

export const Wrapper = styled(Grid)`
  background-color: ${({ customerImpersonation, theme }) =>
    customerImpersonation ? theme.color.red.shade6 : config.environmentColor};
  font-size: 14px;
  grid-template-rows: ${HEADER_HEIGHT}px 1px;
  ${mediaQueries.tablet} {
    grid-template-rows: ${HEADER_HEIGHT}px 1px auto;
  }
`

export const FlexContainer = styled.div`
  grid-column: 1 / span 1;
  display: flex;
`

export const HeaderPosition = styled.div`
  position: fixed;
  z-index: 1060;
  top: 0;
  width: 100%;
`

export const NavWrapper = styled.div`
  display: flex;
  grid-column: span 3;
  ${mediaQueries.tablet} {
    grid-column: 2 / 7;
  }
  & > a {
    border-left: ${({ theme }) => `1px solid ${theme.color.grey.shade8}`};
    border-right: ${({ theme }) => `1px solid ${theme.color.grey.shade8}`};
  }
  & a:last-of-type {
    border-left: 0;
  }
`

export const NavEntries = styled.div`
  display: flex;
  grid-column: 11 / span 2;
  width: 100%;
  justify-content: flex-end;
  transform: translateX(1.25rem);
  ${mediaQueries.tablet} {
    transform: unset;
    grid-column: 6 / 13;
  }
`

export const QuickLinkItem = styled(Link).attrs({ reloadDocument: window.appUpdateAvailable })`
  position: relative;
  display: flex;
  justify-content: center;
  text-decoration: none;
  padding-left: 12px;
  padding-right: 12px;
  background-color: transparent;
  ${mediaQueries.phone} {
    padding-left: 8px;
    padding-right: 8px;
  }
`

export const SellLinkItem = styled(Link).attrs({ reloadDocument: window.appUpdateAvailable })`
  position: relative;
  display: flex;
  justify-content: center;
  text-decoration: none;
  padding-left: 2rem;
  background-color: ${({ theme }) => theme.color.grey.shade10};
  margin: 0 -2rem 0 1rem;
  padding-right: 2rem;
  border-left: ${({ theme }) => `1px solid ${theme.color.grey.shade8}`};
  ${mediaQueries.phone} {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 -1rem 0 0.5rem;
  }
`

export const CenterFlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const QuickLinkContent = styled.div`
  display: flex;
  flex-direction: ${region !== 'SE' ? 'column' : 'row'};
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const Label = styled(P)`
  padding-right: 8px;
  ${mediaQueries.smallPhone} {
    display: none;
  }
`

// props are being passed down to Link-component like this: {...props}, so we need to filter out active, e.g.
export const NavItem = styled(({ active, ...props }) => (
  <Link reloadDocument={window.appUpdateAvailable} {...props} />
))`
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  background-color: ${({ active, theme }) => (active ? theme.color.grey.shade10 : 'transparent')};
  &:hover {
    text-decoration: none;
  }
`

export const NavFlexContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const NavContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// props are being passed down to P-component like this: {...props}, so we need to filter out active, e.g.
export const NavLabel = styled(({ active, ...props }) => <H7 {...props} />)`
  color: ${(props) =>
    props.active ? props.theme.color.grey.shade2 : props.theme.color.grey.shade6};
`

export const GreyBorder = styled.div`
  grid-row: 2;
  grid-column: 1 / 13;
  margin: 0 -36px;
  background-color: ${({ theme }) => `${theme.color.grey.shade8}`};
  ${({ theme }) => theme.layout.contentPadding}
`

export const StyledNotificationBadge = styled(NotificationBadge)`
  right: 0px;
  top: 8px;
  z-index: 1;
`
