import React, { useEffect } from 'react'
import styled from 'styled-components'
import preventScroll from 'prevent-scroll'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4002;
  box-sizing: border-box;
  padding-top: 8px;
`

const InputUnderlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${40 + 8 * 2 + 8}px;
  background-color: white;
  z-index: 4001;
`

export const MobileSearchModal = ({ children }) => {
  useEffect(() => {
    /**
     * This is a hacky solution to make sure scroll prevention is not enabled before the view is scrolled to the top.
     */
    setTimeout(() => {
      preventScroll.on()
    }, 200)
    return () => {
      preventScroll.off()
    }
  }, [])

  return (
    <>
      <InputUnderlay />
      <Container>{children}</Container>
    </>
  )
}
