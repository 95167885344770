import { marketplace } from '@sellpy/commons'
import i18n from 'i18next'

let lang = process.env.LOCALE_OVERRIDE || marketplace.LOCALE[process.env.REGION]

const toggleTranslationKeys = () => {
  lang =
    lang === marketplace.LOCALE[process.env.REGION]
      ? 'cimode'
      : marketplace.LOCALE[process.env.REGION]

  i18n.changeLanguage(lang)
}

const keysDown = new Set()

export const initializeToggleTranslationShortcut = () => {
  const handleKeyDown = (event) => {
    keysDown.add(event.code)
    if (keysDown.has('AltLeft') && keysDown.has('KeyT')) toggleTranslationKeys()
  }

  const handleKeyUp = (event) => {
    keysDown.delete(event.code)
  }

  window.addEventListener('keydown', handleKeyDown)
  window.addEventListener('keyup', handleKeyUp)
}
