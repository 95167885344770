import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import SubLabel from '../components/SubLabel.jsx'
import RadioButton from './RadioButton.jsx'

export const RADIO_BUTTON_DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right'
}

const RadioGroupWrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: relative;
  border: 0px;
  margin: 0px;
  ${({ direction }) =>
    direction === RADIO_BUTTON_DIRECTIONS.RIGHT
      ? `
        width: auto; 
        align-items: stretch;`
      : `
        width: fit-content; 
        align-items: start;`}
`

const StyledSubLabel = styled(SubLabel)`
  ${({ direction }) =>
    direction === RADIO_BUTTON_DIRECTIONS.RIGHT ? 'width: auto; ' : 'width: fit-content;'}
`

const RadioGroup = ({
  direction = RADIO_BUTTON_DIRECTIONS.LEFT,
  disabled,
  disabledOptions,
  register,
  name,
  registerOptions,
  errors,
  helpText,
  options
}) => {
  return (
    <div>
      <RadioGroupWrapper direction={direction}>
        {options.map((option, index) => {
          return (
            <RadioButton
              disabled={disabled || disabledOptions?.includes(option.value)}
              key={`${name}${index}`}
              value={option.value}
              label={option.label}
              erroneous={Boolean(get(errors, name))}
              {...register(name, registerOptions)}
              direction={direction}
            />
          )
        })}
      </RadioGroupWrapper>
      {(helpText || Boolean(errors)) && (
        <StyledSubLabel
          errors={errors}
          name={name}
          disabled={disabled}
          direction={direction}
          label={helpText}
        />
      )}
    </div>
  )
}

const radioButtonArrayValidation = (propTypeCheck) => (props, propName, componentName) => {
  if (Array.isArray(props[propName]) && props[propName]?.length < 2) {
    return new Error(
      'Invalid prop `' +
        propName +
        '` supplied to' +
        ' `' +
        componentName +
        '` with name = `' +
        props['name'] +
        '`. Radio groups must contain more than one element.'
    )
  }
  return PropTypes.checkPropTypes(
    {
      [propName]: propTypeCheck
    },
    props,
    propName,
    componentName
  )
}

RadioGroup.propTypes = {
  direction: PropTypes.oneOf(Object.values(RADIO_BUTTON_DIRECTIONS)),
  disabled: PropTypes.bool,
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  registerOptions: PropTypes.object,
  errors: PropTypes.object.isRequired,
  helpText: PropTypes.string,
  options: radioButtonArrayValidation(
    PropTypes.arrayOf(
      PropTypes.shape({
        defaultChecked: PropTypes.bool,
        value: PropTypes.string.isRequired,
        label: PropTypes.any.isRequired // validated in the RadioButton component
      })
    ).isRequired
  )
}

export default RadioGroup
