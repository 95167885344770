/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components'
import { mediaQueries } from '../../../../uiComponents/mediaQueries'

export const StyledSearch = styled('search')`
  z-index: 4003;
  margin: auto;
  width: 100%;
  position: relative;
  grid-column: 5 / 10;
  ${mediaQueries.largeDesktop} {
    grid-column: 6 / 11;
  }
  ${mediaQueries.tablet} {
    grid-column: 1 / 13;
    padding: 8px 0;
    grid-row: 3;
  }
`

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;
  border-radius: 999px;
  background-color: ${({ theme }) => theme.color.grey.shade9};
  ${mediaQueries.tablet} {
    margin: ${({ isFocused }) => (isFocused ? '0 16px' : 0)};
  }
`

export const StyledInput = styled.input`
  padding-left: 0;
  padding-right: 0;
  border: none;
  margin: 0;
  height: 40px;
  flex-grow: 1;
  font-family: inherit;
  font-size: 14px;
  background-color: ${({ theme }) => theme.color.grey.shade9};
  outline: none;
  min-width: 0;
  ::-webkit-search-decoration {
    display: none;
  }
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`

export const ClearButton = styled.button`
  border: none;
  cursor: pointer;
  margin: 0;
  padding: ${({ isLastButton }) => (isLastButton ? '0 16px 0 8px' : '0 8px 0 8px')};
  min-width: 30px;
  outline: none;
  align-items: center;
  background-color: transparent;
`

export const CancelButton = styled.button`
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0px 8px 0 16px;
  min-width: 30px;
  outline: none;
  align-items: center;
  background-color: transparent;
`

export const Cover = styled.div`
  z-index: 4000;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  min-width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  overflow: hidden;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
    `}
`
